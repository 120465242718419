
.nav-link {
    width: 87%;
    padding: 0.75rem 1rem;
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.75);
    transition: 0.2s;
    font-size: 15px;
    
}

.nav-link-icon {
    padding-right: 10px;
}

.nav-link.active {
    background-color: rgba(255,255,255,0.25);
    color: rgba(255, 255, 255, 1);
}

.nav-link:hover {
    background-color: rgba(255,255,255,0.15);
    color: rgba(255, 255, 255, 1);
    transition: 0.2s;
}