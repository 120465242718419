:root {
  --wing-blue: #16105D;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#wrapper {
  padding-left: 250px;
}

#sidebar {
  left: 0px;
  width: 250px;
  background: var(--wing-blue);
  position: fixed;
  height: 100%;
  padding-top: 5px;
  overflow-y: auto;
  z-index: 1000;
}

#content {
  padding-top: 10px;
  padding-left: 35px;
  padding-right: 35px;
}